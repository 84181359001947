import { createSlice } from '@reduxjs/toolkit';
import { axios } from 'src/utils/axiosHook';

const initialState = {
  prices: [],
  ulf: 11
};

const slice = createSlice({
  name: 'launchpads',
  initialState,
  reducers: {
    getPrices(state, action) {
      state.prices = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getPrices = () => async (dispatch) => {
  axios
    .get('/prices')
    .then(function (response) {
      // handle success
      dispatch(slice.actions.getPrices(response.data));
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });
};
