/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LoadingScreen from 'src/components/LoadingScreen';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  Drawer,
  Grid,
  Hidden,
  Link,
  List,
  ListSubheader,
  Paper,
  Typography,
  makeStyles
} from '@material-ui/core';
import { User as UserIcon, Calendar as CalendarIcon, Mail as MailIcon, Loader, Users, UploadCloud, Gift, CheckCircle, Award } from 'react-feather';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Alert } from '@material-ui/lab';
import { BiRocket, BiBadge } from 'react-icons/bi';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import NumberFormat from 'react-number-format';
import { useSelector } from 'src/store';
import { useWeb3React } from '@web3-react/core';
import { useStakingContract } from 'src/context/StakingContract';
import { NetworksEnums } from '../../../config/constants';

const sections = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Profile',
        href: '/app/profile',
        icon: UserIcon
      },
      {
        title: 'Long Term Staking',
        href: '/app/longtermstaking',
        icon: CheckCircle
      },
      {
        title: 'Launchpad Farming Pools',
        icon: UploadCloud,
        href: '/app/launchpad-staking-pools'
      },
      {
        title: 'Leaderboard',
        icon: Award,
        href: '/app/leaderboard'
      }
    ]
  },
  {
    subheader: 'Applications',
    items: [
      {
        title: 'Launchpads',
        icon: BiRocket,
        href: '/app/launchpads'
      },
      {
        title: 'Airdrops',
        icon: Gift,
        href: '/app/airdrops'
      },
      {
        title: 'Live Drawings',
        icon: Users,
        href: '/app/lottery'
      },
      {
        title: 'Barter Contract',
        icon: BiBadge,
        href: '/app/bartercontract'
      },
      {
        title: 'Calendar',
        icon: CalendarIcon,
        href: '/app/calendar'
      },
      {
        title: 'Mail',
        href: '/app/mail',
        icon: MailIcon
      },
      {
        title: 'KYC',
        href: '/app/sekuritance',
        icon: CheckCircle
      }
    ]
  },
  {
    subheader: 'Software Services',
    items: [
      {
        title: 'Token Vesting',
        icon: Loader,
        href: '/app/tokenvesting'
      }
    ]
  }
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, pathname, depth }), [])}</List>;
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem depth={depth} icon={item.icon} key={key} open={Boolean(open)} title={item.title}>
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} icon={item.icon} key={key} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  navBar: {
    paddingTop: 1
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  card: {
    backgroundColor: theme.palette.background.dark,
    paddingBottom: '20px',
    paddingTop: '20px'
  },
  networkButton: {
    borderRadius: 0
  },
  overview: {
    paddingLeft: 0,
    paddingRight: 0
  },
  networkButtonBox: {
    marginTop: '1px'
  },
  logo: {
    width: '24px',
    height: '24px',
    marginRight: theme.spacing(1)
  },
  dataRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: '20px',
    paddingRight: '20px'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { state } = useStakingContract();
  const acc = useSelector((state) => state.account);
  const [stake, setStake] = useState(0);
  const { chainId } = useWeb3React();
  const [nativeChainCurrency, setNativeChainCurrency] = useState('ETH');
  const [rewards, setRewards] = useState(0);
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const isOnMatic = state?.chainInfo?.tokenSymbol === 'MATIC';
  const isOnEth = state?.chainInfo?.tokenSymbol === 'ETH';
  const isOnBinance = state?.chainInfo?.tokenSymbol === 'BNB';
  const isOnAvalanche = state?.chainInfo?.tokenSymbol === 'AVAX';

  useEffect(() => {
    if (acc.loggedIn && chainId) {
      let stake = acc.ethStake;
      let rewards = acc.ethRewards;
      let currency = 'ETH';
      if (chainId === 137 || chainId === 80001) {
        stake = acc.maticStake;
        rewards = acc.maticRewards;
        currency = 'POLYGON';
      } else if (chainId === 56 || chainId === 97) {
        stake = acc.bscStake;
        rewards = acc.bscRewards;
        currency = 'BSC';
      } else if (chainId === 43114 || chainId === 43113) {
        stake = acc.avalancheStake;
        rewards = acc.avalancheRewards;
        currency = 'AVAX';
      }
      setStake(stake);
      setRewards(rewards);
      setNativeChainCurrency(currency);
    } else {
      setStake(0);
      setNativeChainCurrency('ETH');
    }
  }, [chainId, acc]);

  const handleNetworkChange = (chainId = 0) => {
    if (chainId === 137 && window.ethereum) {
      if (process.env.REACT_APP_ETH_PROVIDER === 'mainnet') {
        window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [NetworksEnums[137]]
        });
      } else {
        window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [NetworksEnums[80001]]
        });
      }
    } else if (chainId === 1 && window.ethereum) {
      if (process.env.REACT_APP_ETH_PROVIDER === 'mainnet') {
        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x1' }]
        });
      } else {
        window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x4' }]
        });
      }
    } else if (chainId === 56 && window.ethereum) {
      if (process.env.REACT_APP_ETH_PROVIDER === 'mainnet') {
        window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [NetworksEnums[56]]
        });
      } else {
        window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [NetworksEnums[97]]
        });
      }
    } else if (chainId === 43114 && window.ethereum) {
      if (process.env.REACT_APP_ETH_PROVIDER === 'mainnet') {
        window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [NetworksEnums[43114]]
        });
      } else {
        window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [NetworksEnums[43113]]
        });
      }
      // workaround to remove listener for avalanche chains!
      const ethereum = window.ethereum;
      ethereum.removeAllListeners(['chainChanged']);
    } else {
      console.error(`ChainId ${chainId} is not supported`);
    }
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box className={classes.navBar} height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box className={classes.networkButtonBox}>
          <Grid container>
            <Grid item xs={6}>
              <Button
                className={classes.networkButton}
                fullWidth
                variant={isOnMatic ? 'contained' : 'outlined'}
                size="medium"
                color="secondary"
                onClick={() => handleNetworkChange(137)}
              >
                <Avatar className={classes.logo} src={'/static/images/coins/matic.png'} />
                Polygon
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.networkButton}
                fullWidth
                variant={isOnEth ? 'contained' : 'outlined'}
                size="medium"
                color="secondary"
                onClick={() => handleNetworkChange(1)}
              >
                <Avatar className={classes.logo} src={'/static/images/coins/eth.png'} />
                Ethereum
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={6}>
              <Button
                className={classes.networkButton}
                fullWidth
                variant={isOnBinance ? 'contained' : 'outlined'}
                size="medium"
                color="secondary"
                onClick={() => handleNetworkChange(56)}
              >
                <Avatar className={classes.logo} src={'/static/images/coins/binance.png'} />
                Binance
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.networkButton}
                fullWidth
                variant={isOnAvalanche ? 'contained' : 'outlined'}
                size="medium"
                color="secondary"
                onClick={() => handleNetworkChange(43114)}
              >
                <Avatar className={classes.logo} src={'/static/images/coins/avalanche.png'} />
                Avalanche
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/profile">
              <Avatar className={classes.cityAvatar}>
                <UserIcon size="22" />
              </Avatar>
            </RouterLink>
          </Box>
          {acc.isLoading ? (
            <Box textAlign="center">
              <LoadingScreen width={180} />
            </Box>
          ) : (
            <Box mt={2} textAlign="center">
              <Link component={RouterLink} to="/app/profile" variant="h5" color="textPrimary" underline="none">
                {acc.loggedIn ? `${acc.address.substr(0, 6)}...${acc.address.substr(acc.address.length - 4, acc.address.length)}` : ''}
              </Link>
            </Box>
          )}
        </Box>
        <Divider />
        <Box p={2}>
          <Paper elevation={10}>
            <Card className={classes.card} variant="outlined">
              <Box textAlign="center">
                <Typography display="inline" variant="body1" color="textSecondary">
                  Your total Score: &nbsp;
                </Typography>
                <Typography display="inline" variant="body1" color="secondary">
                  <NumberFormat value={acc.loggedIn ? acc.totalScore : 0} displayType={'text'} thousandSeparator={true} />
                </Typography>
              </Box>
              <Divider />
              <Box justifyContent="center" display="flex" pl={2} pr={2} pt={2}>
                <Button size="small" color="secondary" variant="contained" component={RouterLink} to="/app/longtermstaking" fullWidth>
                  {acc.loggedIn && acc.totalScore > 0 ? 'Stake more' : 'Stake'}
                </Button>
              </Box>
            </Card>
          </Paper>
        </Box>
        <Divider />
        <Button color="secondary" fullWidth className={classes.expandButton} onClick={handleToggle}>
          <span className={classes.title}>OLD STAKING POOL STATS</span>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>
          <Box>
            <Alert severity="info">
              TrustSwap now uses the “Long Term Staking Pool”. Longer stakes result in higher rewards, and larger launchpad allocations! Check it out!
              <Box mt={1} display="flex" justifyContent="center">
                <Button fullWidth component={RouterLink} size="small" to="/app/longtermstaking" variant="contained" color="secondary">
                  Stake Now
                </Button>
              </Box>
            </Alert>
          </Box>
          <Box p={2}>
            <Paper elevation={10}>
              <Card className={classes.card} variant="outlined">
                <div className={classes.dataRow}>
                  <Typography display="inline" variant="body1" color="textSecondary">
                    Total Stake
                  </Typography>
                  <Typography display="inline" variant="body1" color="secondary">
                    <NumberFormat value={acc.loggedIn ? Math.round(acc.totalStake * 10) / 10 : 0} displayType={'text'} thousandSeparator={true} />
                  </Typography>
                </div>
                <div className={classes.dataRow}>
                  <Typography display="inline" variant="body1" color="textSecondary">
                    {nativeChainCurrency} Stake
                  </Typography>
                  <Typography display="inline" variant="body1" color="secondary">
                    <NumberFormat value={acc.loggedIn ? Math.round(stake * 10) / 10 : 0} displayType={'text'} thousandSeparator={true} />
                  </Typography>
                </div>
                <div className={classes.dataRow}>
                  <Typography variant="body1" color="textSecondary">
                    Rewards
                  </Typography>
                  <Typography display="inline" variant="body1" color="secondary">
                    <NumberFormat value={acc.loggedIn ? Math.round(rewards * 10) / 10 : 0} displayType={'text'} thousandSeparator={true} />
                  </Typography>
                </div>
                <Box mt={1}>
                  <Divider />
                  <Box justifyContent="center" display="flex" pl={2} pr={2} pt={2}>
                    <Button size="small" variant="contained" color="secondary" component={RouterLink} to="/app/withdrawStake" fullWidth>
                      Withdraw
                    </Button>
                  </Box>
                </Box>
              </Card>
            </Paper>
          </Box>
        </Collapse>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>

        <Divider />

        <Box p={2}>
          <Paper elevation={10}>
            <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
              <Typography variant="h6" color="textPrimary">
                Need Help?
              </Typography>
              <Link variant="subtitle1" color="secondary" component="a" href="https://trustswap.gitbook.io/trustswap/" target="_blank">
                Learn More
              </Link>
            </Box>
          </Paper>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
