import { network } from '../../config/constants';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '../WalletSelector/walletLinkConnector';

/* export const injected = new InjectedConnector({
  supportedChainIds: [network.chainID],
});
 */
export const injected = new InjectedConnector({
  supportedChainIds: [
    1, // ETH - mainnet
    3, // ETH - ropsten
    4, // ETH - rinkeby
    5, // ETH - goerli
    42,
    97, // BSC - testnet
    56, // BSC - mainnet
    43113, // AVAX - testnet
    43114, // AVAX - mainnet
    137, // MATIC - mainnet
    80001 // MATIC - mumbai
  ]
});

const rpc = {};
rpc[network.chainID] = network.url;

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: rpc,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 15000
});

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: network.url,
  appName: 'TrustSwap | Staking',
  appLogoUrl:
    'https://assets.coingecko.com/coins/images/11795/large/Trustswap.png?1594311216'
});
