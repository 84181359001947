module.exports = {
  contractName: 'StakingPoolContract',
  abi: [
    {
      inputs: [],
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'newOwner',
          type: 'address'
        }
      ],
      name: 'OwnerChanges',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'OwnerSetReward',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'poolDuration',
          type: 'uint256'
        }
      ],
      name: 'PoolDurationChange',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'userAddress',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'RewardWithdrawal',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'userAddress',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'Staked',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'address',
          name: 'userAddress',
          type: 'address'
        },
        {
          indexed: false,
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'StakingWithdrawal',
      type: 'event'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_userAddress',
          type: 'address'
        }
      ],
      name: 'calculateReward',
      outputs: [
        {
          internalType: 'uint256',
          name: '_reward',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getPoolDetails',
      outputs: [
        {
          internalType: 'address',
          name: '_baseToken',
          type: 'address'
        },
        {
          internalType: 'address',
          name: '_pairedToken',
          type: 'address'
        },
        {
          internalType: 'uint256',
          name: '_totalRewards',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: '_rewardsWithdrawn',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: '_poolStartTime',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: '_poolEndTime',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getPoolDuration',
      outputs: [
        {
          internalType: 'uint256',
          name: '_poolDuration',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_userAddress',
          type: 'address'
        }
      ],
      name: 'getStakingAmount',
      outputs: [
        {
          internalType: 'uint256',
          name: '_stakedAmount',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getSwapAddress',
      outputs: [
        {
          internalType: 'address',
          name: '_swapAddress',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'userAddress',
          type: 'address'
        }
      ],
      name: 'getTotalRewardCollectedByUser',
      outputs: [
        {
          internalType: 'uint256',
          name: '_totalRewardCollected',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getTotalRewards',
      outputs: [
        {
          internalType: 'uint256',
          name: '_totalRewards',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'getTotalStaked',
      outputs: [
        {
          internalType: 'uint256',
          name: '_totalStaked',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'owner',
      outputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_rewardAmount',
          type: 'uint256'
        }
      ],
      name: 'ownerSetPoolRewards',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'rewardToken',
      outputs: [
        {
          internalType: 'contract IERC20',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: '_poolDuration',
          type: 'uint256'
        }
      ],
      name: 'setPoolDuration',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_address',
          type: 'address'
        }
      ],
      name: 'setRewardTokenAddress',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '_address',
          type: 'address'
        }
      ],
      name: 'setTswapAddress',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'stake',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: 'newOwner',
          type: 'address'
        }
      ],
      name: 'transferOwnership',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [],
      name: 'tswap',
      outputs: [
        {
          internalType: 'contract IERC20',
          name: '',
          type: 'address'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        }
      ],
      name: 'userRewardInfo',
      outputs: [
        {
          internalType: 'uint256',
          name: 'totalWithdrawn',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'lastWithdrawTime',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        },
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      name: 'userStaking',
      outputs: [
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'stakingTime',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'lastWithdrawTime',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'address',
          name: '',
          type: 'address'
        }
      ],
      name: 'userTotalStaking',
      outputs: [
        {
          internalType: 'uint256',
          name: 'totalStaking',
          type: 'uint256'
        },
        {
          internalType: 'uint256',
          name: 'totalStakingTIme',
          type: 'uint256'
        }
      ],
      stateMutability: 'view',
      type: 'function'
    },
    {
      inputs: [],
      name: 'withdrawRewardsOnly',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      inputs: [
        {
          internalType: 'uint256',
          name: 'amount',
          type: 'uint256'
        }
      ],
      name: 'withdrawStaking',
      outputs: [],
      stateMutability: 'nonpayable',
      type: 'function'
    }
  ]
};
