import { createSlice } from '@reduxjs/toolkit';
import { axios } from 'src/utils/axiosHook';

const initialState = {
  address: '',
  loggedIn: false,
  hasAdminRole: false,
  ethStake: 0,
  maticStake: 0,
  bscStake: 0,
  avalancheStake: 0,
  totalStake: 0,
  swapscore: 0,
  swapscoreRank: 0,
  rewards: 0,
  accumulatedRewards: 0,
  swapHoldingsOnEth: 0,
  swapHoldingsOnMatic: 0,
  swapHoldingsOnBSC: 0,
  swapHoldingsOnAvalanche: 0,
  maticTransactions: [],
  ethTransactions: [],
  bscTransactions: [],
  avalancheTransactions: [],
  token: '',
  usdcHoldings: {
    1: 0,
    56: 0,
    137: 0,
    43114: 0
  },
  usdtHoldings: {
    1: 0,
    56: 0,
    137: 0,
    43114: 0
  },
  score: {
    1: 0,
    56: 0,
    137: 0,
    43114: 0
  },
  stakedAmount: {
    1: 0,
    56: 0,
    137: 0,
    43114: 0
  },
  totalScore: 0,
  totalStakedAmount: 0,
  isLoading: false
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    login(state, action) {
      const {
        address = '',
        swapscore = 0,
        swapscoreRank = 0,
        ethStake = 0,
        maticStake = 0,
        bscStake = 0,
        avalancheStake = 0,
        totalStake = 0,
        ethRewards = 0,
        maticRewards = 0,
        bscRewards = 0,
        avalancheRewards = 0,
        hasAdminRole = false,
        accumulatedRewards = 0,
        swapHoldingsOnEth = 0,
        swapHoldingsOnMatic = 0,
        swapHoldingsOnBSC = 0,
        swapHoldingsOnAvalanche = 0,
        maticTransactions = [],
        ethTransactions = [],
        bscTransactions = [],
        avalancheTransactions = [],
        token = '',
        usdcHoldings = {
          1: 0,
          56: 0,
          137: 0,
          43114: 0
        },
        usdtHoldings = {
          1: 0,
          56: 0,
          137: 0,
          43114: 0
        },
        score = {
          1: 0,
          56: 0,
          137: 0,
          43114: 0
        },
        stakedAmount = {
          1: 0,
          56: 0,
          137: 0,
          43114: 0
        },
        totalScore = 0,
        totalStakedAmount = 0,
        isLoading = false
      } = action.payload;
      state.loggedIn = true;
      state.hasAdminRole = hasAdminRole;
      state.address = address;
      state.swapscoreRank = swapscoreRank;
      state.swapscore = swapscore;
      state.ethStake = ethStake;
      state.maticStake = maticStake;
      state.bscStake = bscStake;
      state.avalancheStake = avalancheStake;
      state.totalStake = totalStake;
      state.ethRewards = ethRewards;
      state.maticRewards = maticRewards;
      state.bscRewards = bscRewards;
      state.avalancheRewards = avalancheRewards;
      state.accumulatedRewards = accumulatedRewards;
      state.swapHoldingsOnEth = swapHoldingsOnEth;
      state.swapHoldingsOnMatic = swapHoldingsOnMatic;
      state.swapHoldingsOnBSC = swapHoldingsOnBSC;
      state.swapHoldingsOnAvalanche = swapHoldingsOnAvalanche;
      state.maticTransactions = maticTransactions;
      state.ethTransactions = ethTransactions;
      state.bscTransactions = bscTransactions;
      state.avalancheTransactions = avalancheTransactions;
      state.token = token;
      state.usdcHoldings = usdcHoldings;
      state.usdtHoldings = usdtHoldings;
      state.score = score;
      state.stakedAmount = stakedAmount;
      state.totalScore = totalScore;
      state.totalStakedAmount = totalStakedAmount;
    },
    logout(state) {
      state.loggedIn = false;
      state.hasAdminRole = false;
      state.address = '';
      state.ethStake = 0;
      state.maticStake = 0;
      state.bscStake = 0;
      state.avalancheStake = 0;
      state.totalStake = 0;
      state.swapscoreRank = 0;
      state.swapscore = 0;
      state.ethRewards = 0;
      state.maticRewards = 0;
      state.bscRewards = 0;
      state.avalancheRewards = 0;
      state.accumulatedRewards = 0;
      state.swapHoldingsOnEth = 0;
      state.swapHoldingsOnMatic = 0;
      state.swapHoldingsOnBSC = 0;
      state.swapHoldingsOnAvalanche = 0;
      state.maticTransactions = [];
      state.ethTransactions = [];
      state.bscTransactions = [];
      state.avalancheTransactions = [];
      state.token = '';
      state.usdcHoldings = {
        1: 0,
        56: 0,
        137: 0,
        43114: 0
      };
      state.usdtHoldings = {
        1: 0,
        56: 0,
        137: 0,
        43114: 0
      };
      (state.score = {
        1: 0,
        56: 0,
        137: 0,
        43114: 0
      }),
        (state.stakedAmount = {
          1: 0,
          56: 0,
          137: 0,
          43114: 0
        }),
        (state.totalScore = 0);
      state.totalStakedAmount = 0;
    },
    isLoading(state, action) {
      state.isLoading = action.payload;
    }
  }
});

export const reducer = slice.reducer;

export const logout = () => async (dispatch) => {
  dispatch(slice.actions.logout());
};

export const login = (address, token) => async (dispatch) => {
  dispatch(slice.actions.isLoading(true));
  axios
    .get(`/accounts/${address}`)
    .then(function (response) {
      // handle success
      response.data.token = token;
      dispatch(slice.actions.login(response.data));
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    .finally(() => {
      dispatch(slice.actions.isLoading(false));
    });
};
