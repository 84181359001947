import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as formReducer } from 'redux-form';
import { reducer as accountReducer } from 'src/slices/account';
import { reducer as priceReducer } from 'src/slices/price';
import { reducer as stakingInfoReducer } from 'src/slices/stakingInfo';
import { reducer as latestTransactionsReducer } from 'src/slices/latestTransactions';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as launchpadsReducer } from 'src/slices/launchpads';
import { reducer as networkReducer } from 'src/slices/network';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  form: formReducer,
  account: accountReducer,
  price: priceReducer,
  stakingInfo: stakingInfoReducer,
  latestTransactions: latestTransactionsReducer,
  mail: mailReducer,
  launchpads: launchpadsReducer,
  network: networkReducer
});

export default rootReducer;
