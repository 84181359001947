/* eslint-disable react/display-name */
import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import StakeLayout from 'src/layouts/StakeLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    path: '/faucet',
    layout: StakeLayout,
    routes: [
      {
        exact: true,
        path: '/faucet',
        component: lazy(() => import('src/views/faucet'))
      }
    ]
  },
  {
    path: '/app',
    layout: StakeLayout,
    routes: [
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/bartercontract',
        component: lazy(() => import('src/views/barter'))
      },
      {
        exact: true,
        path: '/app/leaderboard',
        component: lazy(() => import('src/views/leaderboard'))
      },
      /* {
        exact: true,
        path: '/app/staking-stats',
        component: lazy(() => import('src/views/reports/DashboardStakingStatsView'))
      }, */
      {
        exact: true,
        path: '/app/swap-stats',
        component: lazy(() => import('src/views/reports/DashboardSwapStatsView'))
      },
      {
        exact: true,
        path: '/app/launchpads',
        component: lazy(() => import('src/views/launchpads'))
      },
      {
        exact: true,
        path: '/app/airdrops',
        component: lazy(() => import('src/views/airdrops'))
      },
      {
        exact: true,
        path: '/app/swapdrops-flashdrops',
        component: lazy(() => import('src/views/drops'))
      },
      {
        exact: true,
        path: '/app/launchpad-staking-pools',
        component: lazy(() => import('src/views/LaunchpadStakingPools'))
      },
      {
        exact: true,
        path: '/app/lottery',
        component: lazy(() => import('src/views/lottery'))
      },
      {
        exact: true,
        path: '/app/tokenvesting',
        component: lazy(() => import('src/views/tokenvesting'))
      },
      {
        exact: true,
        path: '/app/stake',
        component: lazy(() => import('src/views/stake'))
      },
      {
        exact: true,
        path: '/app/withdrawStake',
        component: lazy(() => import('src/views/withdrawStake'))
      },
      {
        exact: true,
        path: '/app/initiateUnstakeFallback',
        component: lazy(() => import('src/views/initiateUnstake'))
      },
      {
        exact: true,
        path: '/app/stakeRewards',
        component: lazy(() => import('src/views/stakeRewards'))
      },
      {
        exact: true,
        path: '/app/withdrawRewards',
        component: lazy(() => import('src/views/withdrawRewards'))
      },
      {
        exact: true,
        path: '/app/createLaunchpad',
        component: lazy(() => import('src/views/launchpads/LaunchpadCreateView'))
      },
      {
        exact: true,
        path: '/app/editLaunchpad/:id',
        component: lazy(() => import('src/views/launchpads/LaunchpadEditView'))
      },
      {
        exact: true,
        path: ['/app/mail/inbox/:mailId?', '/app/mail/sent/:mailId?'],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/profile',
        component: lazy(() => import('src/views/profile'))
      },
      {
        exact: true,
        path: '/app/sekuritance',
        component: lazy(() => import('src/views/iFrames'))
      },
      {
        exact: true,
        path: '/app/longtermstaking',
        component: lazy(() => import('src/views/lockedStaking'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/profile" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
