import { ethers } from 'ethers';
import SwapTokenABI from '../contract-builds/SwapToken.json';
import StakingABI from '../contract-builds/StakingContract.json';
import poolContractData from '../contract-builds/StakingPoolContract';
import newPoolContractData from '../contract-builds/FixedStakingPoolContract';
import formatBigNumber from '../../utils/formatBigNumber';
import { bignumber, multiply } from 'mathjs';
import ERC20 from 'src/lib/abi/ERC20';
import {
  getNetworkName,
  getDeploymentBlock,
  getPoolContracts,
  getPoolContractById,
  getFixedPoolContracts,
  getFixedPoolContractById,
  SupportedNetworks
} from '../../config/constants';

const NETWORK_ROPSTEN = 'ropsten';
const NETWORK_DEVELOPMENT = 'development';
const NETWORK_MAINNET = 'mainnet';
const NETWORK_TESTNET = 'testnet';

export default class StakingContract {
  provider;
  stakingContract;
  stakingPoolContracts = {};
  fixedStakingPoolContracts = {};
  stakingPoolTokenContracts = {};
  stakingPoolRewardTokenContracts = {};
  tokenContract;
  currentNetwork;

  constructor(network = SupportedNetworks.ethereum) {
    this.initializeProvider();
    this.intitalizeContractInstance(network);
  }

  initializeProvider() {
    let canInitialize = false;
    switch (StakingContract.getProvider()) {
      case NETWORK_ROPSTEN:
      case NETWORK_MAINNET:
      case NETWORK_TESTNET:
      default:
        canInitialize = this.setLocalProvider();
    }

    if (!canInitialize) {
      this.setDummyProvider();
    }
  }

  intitalizeContractInstance(network) {
    if (network === SupportedNetworks.ethereum) {
      try {
        this.stakingContract = new ethers.Contract(SupportedNetworks.ethereum.stakingContractAddress, StakingABI, this.provider);
      } catch (e) {
        console.warn('could not initialize stakingContract: ', SupportedNetworks.ethereum.swapTokenContract);
        throw e;
      }

      try {
        this.tokenContract = new ethers.Contract(SupportedNetworks.ethereum.swapTokenContract, SwapTokenABI, this.provider);
      } catch (e) {
        console.warn('could not initialize tokenContract: ', SupportedNetworks.ethereum.swapTokenContract);
        throw e;
      }
    } else if (network === SupportedNetworks.bsc) {
      try {
        this.stakingContract = new ethers.Contract(SupportedNetworks.bsc.stakingContractAddress, StakingABI, this.provider);
      } catch (e) {
        console.warn('could not initialize layer 2 stakingContract: ', SupportedNetworks.bsc.swapTokenContract);
      }

      try {
        this.tokenContract = new ethers.Contract(SupportedNetworks.bsc.swapTokenContract, SwapTokenABI, this.provider);
      } catch (e) {
        console.warn('could not initialize layer 2 tokenContract: ', SupportedNetworks.bsc.swapTokenContract);
        throw e;
      }
    } else if (network === SupportedNetworks.avalanche) {
      try {
        this.stakingContract = new ethers.Contract(SupportedNetworks.avalanche.stakingContractAddress, StakingABI, this.provider);
      } catch (e) {
        console.warn('could not initialize layer 2 stakingContract: ', SupportedNetworks.avalanche.swapTokenContract);
      }

      try {
        this.tokenContract = new ethers.Contract(SupportedNetworks.avalanche.swapTokenContract, SwapTokenABI, this.provider);
      } catch (e) {
        console.warn('could not initialize layer 2 tokenContract: ', SupportedNetworks.avalanche.swapTokenContract);
        throw e;
      }
    } else {
      try {
        this.stakingContract = new ethers.Contract(SupportedNetworks.matic.stakingContractAddress, StakingABI, this.provider);
      } catch (e) {
        console.warn('could not initialize layer 2 stakingContract: ', SupportedNetworks.matic.swapTokenContract);
      }

      try {
        this.tokenContract = new ethers.Contract(SupportedNetworks.matic.swapTokenContract, SwapTokenABI, this.provider);
      } catch (e) {
        console.warn('could not initialize layer 2 tokenContract: ', SupportedNetworks.matic.swapTokenContract);
        throw e;
      }
    }

    try {
      for (const [key, value] of Object.entries(getPoolContracts())) {
        this.stakingPoolContracts[key] = new ethers.Contract(value.poolContractAddress, poolContractData.abi, this.provider);
      }

      for (const [key, value] of Object.entries(getFixedPoolContracts())) {
        this.fixedStakingPoolContracts[key] = new ethers.Contract(value.poolContractAddress, newPoolContractData.abi, this.provider);

        this.stakingPoolTokenContracts[key] = new ethers.Contract(value.stakeToken.address, ERC20, this.provider);

        this.stakingPoolRewardTokenContracts[key] = new ethers.Contract(value.rewardToken.address, ERC20, this.provider);
      }
    } catch (e) {
      console.warn('could not initialize stakingContract: ', this.currentNetwork.stakingContractAddress);
      throw e;
    }

    this.currentNetwork = network;
  }

  static hasMetamask() {
    return typeof window.web3 !== 'undefined';
  }

  getCurrentNetwork() {
    return this.currentNetwork;
  }

  async metamaskEnabled() {
    try {
      await this.provider.getSigner().getAddress();
      return true;
    } catch (e) {
      return false;
    }
  }

  static enableMetamask() {
    return window.ethereum.enable();
  }

  ethBalance() {
    this.setDefaultSigners();
    return this.provider.getSigner().getBalance();
  }

  swapBalance(address) {
    return this.tokenContract.balanceOf(address).then((bigNumVal) => {
      return ethers.utils.formatEther(bigNumVal);
    });
  }

  getSwapTotalSupply() {
    return this.tokenContract.totalSupply().then((bigNumVal) => {
      return ethers.utils.formatEther(bigNumVal);
    });
  }

  getSwapTotalBurned() {
    return this.tokenContract.totalSupply().then((totalSupply) => {
      const initTotalSupply = ethers.utils.formatEther('100000000000000000000000000');
      const remTotalSupply = ethers.utils.formatEther(totalSupply);
      return initTotalSupply - remTotalSupply;
    });
  }

  getAllowance(address) {
    return this.tokenContract.allowance(address, this.currentNetwork.stakingContractAddress).then((bigNumVal) => {
      return ethers.utils.formatEther(bigNumVal);
    });
  }

  getStakeDeposit(address) {
    return this.stakingContract
      .getStakeDetails(address)
      .then((res) => {
        return res;
      })
      .catch(() => {
        return false;
      });
  }

  alreadyStaked(address) {
    return this.stakingContract
      .getStakeDetails(address)
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  contractTotalStakeLimit() {
    return this.stakingContract.maxStakingAmount().then((bigNumVal) => {
      return bigNumVal.toString();
    });
  }

  currentTotalStake() {
    return this.stakingContract.currentTotalStake().then((bigNumVal) => {
      return bigNumVal.toString();
    });
  }

  totalRewardsDistributed() {
    return this.stakingContract.totalRewardsDistributed().then((bigNumVal) => {
      return parseInt(ethers.utils.formatEther(bigNumVal), 10);
    });
  }

  async getRewardsAccumulated() {
    const rewardsAddress = await this.stakingContract.rewardsAddress();
    let [rewardsWithdrawn, rewardsDistributed, rewardsBalance] = await Promise.all([
      this.stakingContract.rewardsWithdrawn(),
      this.stakingContract.rewardsDistributed(),
      this.tokenContract.balanceOf(rewardsAddress)
    ]);
    const result = rewardsBalance.add(rewardsWithdrawn).sub(rewardsDistributed);
    return Math.max(parseInt(ethers.utils.formatEther(result.toString()), 10), 0);
  }

  async getLatestBlock() {
    const topicTransfer = ethers.utils.id('RewardsDistributed(uint256)'); //This is the interface for your event
    const logs = await this.provider.getLogs({
      fromBlock: getDeploymentBlock(),
      address: this.currentNetwork.stakingContractAddress, // Address of contract
      toBlock: 'latest',
      topics: [topicTransfer]
    });
    if (logs && logs.length) {
      // eslint-disable-next-line no-unused-vars
      const last = this.stakingContract.interface.parseLog(logs[logs.length - 1]);
      const lastBlock = await this.provider.getBlock(logs[logs.length - 1].blockNumber);
      return lastBlock.timestamp;
    }
    return null;
  }

  approveContract(value) {
    this.setDefaultSigners();
    return this.tokenContract.approve(this.currentNetwork.stakingContractAddress, ethers.utils.parseEther(value));
  }

  stakeSWAP(value) {
    this.setDefaultSigners();
    return this.stakingContract.deposit(ethers.utils.parseEther(value)).catch((e) => console.error('stake err', e));
  }

  setLocalProvider() {
    if (!window.web3) {
      console.warn('metamask not installed');
      return false;
    }
    this.provider = new ethers.providers.Web3Provider(window.web3.currentProvider);
    this.provider.ready.catch((e) => console.error('Could not create Web3Provider: ', e));
    return true;
  }

  setDummyProvider() {
    switch (StakingContract.getProvider()) {
      case NETWORK_ROPSTEN:
      case NETWORK_TESTNET:
        this.provider = new ethers.providers.InfuraProvider('ropsten');
        this.provider.ready.catch((e) => console.error('Could not create read-only InfuraProvider: ', e));
        break;
      case NETWORK_MAINNET:
        this.provider = new ethers.providers.InfuraProvider('homestead');
        this.provider.ready.catch((e) => console.error('Could not create read-only InfuraProvider: ', e));
        break;
      default:
        this.provider = new ethers.providers.JsonRpcProvider('http://127.0.0.1:7545');
        this.provider.ready.catch((e) => console.error('Could not create read-only JsonRpcProvider for development mode: ', e));
    }
  }

  initiateWithdrawal(value) {
    this.setDefaultSigners();
    console.log('unstaking value:');
    console.log(ethers.utils.parseEther(value));
    console.log(ethers.utils.parseEther(value).toString());
    return this.stakingContract.initiateWithdrawal(ethers.utils.parseEther(value));
  }

  executeWithdrawal() {
    this.setDefaultSigners();
    return this.stakingContract.executeWithdrawal();
  }

  withdrawRewards() {
    this.setDefaultSigners();
    try {
      return this.stakingContract.withdrawRewards();
    } catch (error) {
      console.log('error in withdrawRewards->', error);
    }
  }

  // Owner functions
  toggleRewards(enabled) {
    this.setDefaultSigners();
    return this.stakingContract.toggleRewards(enabled);
  }

  togglePaused(enabled) {
    this.setDefaultSigners();
    if (enabled) {
      return this.stakingContract.unpause();
    }
    return this.stakingContract.pause();
  }

  approvePoolContract(id, value) {
    this.setDefaultSigners();
    return this.tokenContract.approve(getPoolContractById(id).poolContractAddress, ethers.utils.parseEther(value));
  }

  getTokenBalance(id, token) {
    this.setDefaultSigners();
    const tokenContract = new ethers.Contract(token, ERC20, this.provider);
    return tokenContract.balanceOf(getFixedPoolContractById(id).stakeToken.address).then((bigNumVal) => {
      return bigNumVal;
    });
  }

  getTokenSupply(id) {
    this.setDefaultSigners();
    return this.stakingPoolTokenContracts[id].totalSupply();
  }

  approveFixedPoolContract(id, value) {
    this.setDefaultSigners();
    return this.stakingPoolTokenContracts[id].approve(getFixedPoolContractById(id).poolContractAddress, ethers.utils.parseEther(value));
  }

  getPoolStakedAmount(id, account) {
    this.setDefaultSigners();
    return this.stakingPoolContracts[id]
      .getStakingAmount(account)
      .then((res) => {
        return Math.floor(ethers.utils.formatEther(res) * 1000) / 1000;
      })
      .catch((e) => console.error('pool err', e));
  }

  // new farming pools
  getFixedPoolStakedAmount(id, account) {
    this.setDefaultSigners();
    return this.fixedStakingPoolContracts[id]
      .userInfo(account)
      .then((res) => {
        return formatBigNumber(res[0], this.getFixedStakeTokenDecimals(id), this.getFixedStakeTokenDecimals(id)).replaceAll(',', '');
      })
      .catch((e) => console.error('pool err', e));
  }

  getTotalStaked(id) {
    this.setDefaultSigners();
    return this.stakingPoolContracts[id].getTotalStaked().then((res) => res);
  }

  getRewardTokenBalance(id, address) {
    this.setDefaultSigners();
    return this.stakingPoolTokenContracts[id].balanceOf(address).then((bigNumVal) => {
      return bigNumVal;
    });
  }

  getPoolTotalStaked(id, isStaking) {
    this.setDefaultSigners();
    if (!isStaking) {
      return this.stakingPoolTokenContracts[id]
        .balanceOf(getFixedPoolContractById(id).poolContractAddress)
        .then((bigNumVal) => {
          return formatBigNumber(bigNumVal, this.getFixedStakeTokenDecimals(id), 3).replaceAll(',', '');
        })
        .catch((e) => console.error('stake err', e));
    } else {
      return this.fixedStakingPoolContracts[id]
        .totalStaked()
        .then((res) => {
          return res;
        })
        .catch((e) => {
          console.error('stake err', e);
          return 0;
        });
    }
  }

  getPoolRemainingRewards(id) {
    this.setDefaultSigners();
    return this.stakingPoolRewardTokenContracts[id]
      .balanceOf(getFixedPoolContractById(id).poolContractAddress)
      .then((bigNumVal) => {
        return bigNumVal;
      })
      .catch((e) => console.error('stake err', e));
  }

  calculateReward(id, account, tokenDecimals) {
    this.setDefaultSigners();
    return this.stakingPoolContracts[id]
      .calculateReward(account)
      .then((bigNumVal) => {
        return formatBigNumber(bigNumVal, tokenDecimals, 3);
      })
      .catch((e) => console.error('calculate pool reward err', e));
  }

  calculatePoolReward(id, account) {
    this.setDefaultSigners();
    return this.fixedStakingPoolContracts[id]
      .pendingReward(account)
      .then((res) => res)
      .catch((e) => console.error('pool reward err', e));
  }

  getPoolDetails(id) {
    this.setDefaultSigners();
    return this.stakingPoolContracts[id]
      .getPoolDetails()
      .then((res) => res)
      .catch(() => {
        return false;
      })
      .catch((e) => console.error('pool details err', e));
  }

  getPoolInfo(id) {
    this.setDefaultSigners();
    return this.fixedStakingPoolContracts[id]
      .poolInfo(0)
      .then((res) => {
        return res;
      })
      .catch((e) => console.error('pool info err', e));
  }

  getPoolStartBlock(id) {
    this.setDefaultSigners();
    return this.fixedStakingPoolContracts[id]
      .startBlock()
      .then((res) => {
        return res;
      })
      .catch(() => {
        return 0;
      })
      .catch((e) => console.error('pool start block err', e));
  }

  getPoolEndBlock(id) {
    this.setDefaultSigners();
    return this.fixedStakingPoolContracts[id]
      .bonusEndBlock()
      .then((res) => {
        return res;
      })
      .catch(() => {
        return 0;
      })
      .catch((e) => console.error('pool end block err', e));
  }

  getPoolRewardsPerBlock(id) {
    this.setDefaultSigners();
    return this.fixedStakingPoolContracts[id]
      .rewardPerBlock()
      .then((res) => {
        return res;
      })
      .catch(() => {
        return 0;
      })
      .catch((e) => console.error('pool block rewards err', e));
  }

  getPoolAllowance(id, address) {
    return this.tokenContract
      .allowance(address, getPoolContractById(id))
      .then((bigNumVal) => {
        return ethers.utils.formatEther(bigNumVal);
      })
      .catch((e) => console.error('get pool allowance err', e));
  }

  // new farming pool
  getFixedPoolAllowance(id, address) {
    return this.stakingPoolTokenContracts[id]
      .allowance(address, getFixedPoolContractById(id).poolContractAddress)
      .then((bigNumVal) => {
        return formatBigNumber(bigNumVal, this.getFixedStakeTokenDecimals(id), this.getFixedStakeTokenDecimals(id)).replaceAll(',', '');
      })
      .catch((e) => console.error('get pool allowance err', e));
  }

  stakeToPool(id, value) {
    this.setDefaultSigners();
    return this.stakingPoolContracts[id].stake(ethers.utils.parseEther(value)).catch((e) => console.error('stake err', e));
  }

  stakeToFixedPool(id, value) {
    this.setDefaultSigners();
    const stakingValueString = multiply(bignumber(value), bignumber(Math.pow(10, this.getFixedStakeTokenDecimals(id)))).toFixed();
    return this.fixedStakingPoolContracts[id].deposit(stakingValueString).catch((e) => console.error('stake to fixed pool err', e));
  }

  unStakeFromPool(id, value) {
    this.setDefaultSigners();
    return this.stakingPoolContracts[id].withdrawStaking(ethers.utils.parseEther(value)).catch((e) => console.error('unstake err', e));
  }

  setDefaultSigners() {
    this.stakingContract = this.stakingContract.connect(this.provider.getSigner());
    for (const [key] of Object.entries(this.stakingPoolContracts)) {
      this.stakingPoolContracts[key] = this.stakingPoolContracts[key].connect(this.provider.getSigner());
    }

    for (const [key] of Object.entries(this.fixedStakingPoolContracts)) {
      this.fixedStakingPoolContracts[key] = this.fixedStakingPoolContracts[key].connect(this.provider.getSigner());

      this.stakingPoolTokenContracts[key] = this.stakingPoolTokenContracts[key].connect(this.provider.getSigner());

      this.stakingPoolRewardTokenContracts[key] = this.stakingPoolRewardTokenContracts[key].connect(this.provider.getSigner());
    }

    this.tokenContract = this.tokenContract.connect(this.provider.getSigner());
  }

  setWeb3ReactProvider(library) {
    if (!library) return;
    this.provider = library;
  }

  getRewardTokenDecimals(id) {
    return getPoolContractById(id)?.rewardToken.decimals ?? 18;
  }

  getFixedRewardTokenDecimals(id) {
    return getFixedPoolContractById(id)?.rewardToken.decimals ?? 18;
  }

  getFixedStakeTokenDecimals(id) {
    return getFixedPoolContractById(id)?.stakeToken.decimals ?? 18;
  }

  getCurrentBlock() {
    return this.provider.getBlockNumber();
  }

  static getProvider() {
    switch (getNetworkName()) {
      case NETWORK_ROPSTEN:
        return NETWORK_ROPSTEN;
      case NETWORK_MAINNET:
        return NETWORK_MAINNET;
      default:
        return NETWORK_DEVELOPMENT;
    }
  }
}
