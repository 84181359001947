import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Box, Button, Toolbar, Hidden, IconButton, SvgIcon, makeStyles, useTheme } from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from '../../../components/Logo';
import LogoLight from '../../../components/Logo_light';
import { THEMES } from 'src/constants';
import Notifications from './Notifications';
import Settings from './Settings';
import { useSelector } from 'src/store';
import WalletSelector from 'src/components/WalletSelector/WalletSelector';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.background.paper
        }
      : {}),
    ...(theme.name === THEMES.DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    minHeight: 64
  },
  borderMedia: {
    borderColor: theme.palette.background.dark
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  button: {
    marginLeft: theme.spacing(2)
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const networkName = useSelector((state) => state.network.name);
  const theme = useTheme();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Box className={classes.borderMedia} borderBottom={1}>
        <Toolbar className={classes.toolbar}>
          <Hidden lgUp>
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon className={classes.icon} fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Hidden>
          <Hidden mdDown>
            <RouterLink to="/">{theme.name === THEMES.LIGHT ? <LogoLight /> : <Logo />}</RouterLink>
          </Hidden>
          <Box ml={2} flexGrow={1} />
          <Settings />
          <Notifications />
          <Hidden mdDown>
            <Box ml={1}>
              <Button color="secondary" variant="outlined" size="small">
                {networkName}
              </Button>
            </Box>
          </Hidden>
          <Box ml={2}>
            <WalletSelector />
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
