import React, { useEffect, useState, useCallback } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { THEMES } from 'src/constants';
import { Box, Button, Card, Container, Grid, Typography, useTheme, useMediaQuery, makeStyles } from '@material-ui/core';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { axios } from 'src/utils/axiosHook';
import { useDispatch, useSelector } from 'src/store';
import { getStakingInfo } from 'src/slices/stakingInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 200,
    paddingBottom: 200,
    minHeight: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  card: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.paper,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  launchpadCard: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.paper,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    justifyContent: 'space-between'
  }
}));

const Hero = ({ className }) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const dispatch = useDispatch();
  const stakingInfo = useSelector((state) => state.stakingInfo);
  const [launchpads, setLaunchpads] = useState(0);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const getLaunchpads = useCallback(async () => {
    try {
      const launchpadsResponse = await axios.get('/launchpadsCount');

      if (isMountedRef.current) {
        setLaunchpads(launchpadsResponse.data.count);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getLaunchpads();
  }, [getLaunchpads]);

  useEffect(() => {
    dispatch(getStakingInfo());
  }, []);

  return (
    <div className={clsx(classes.root, className)}>
      {mobileDevice ? (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                <Typography variant="overline" color="secondary">
                  Introducing
                </Typography>
                <Typography variant="h1" color="textPrimary">
                  Trustswap - Dashboard
                </Typography>
                <Box mt={4} bt={4}>
                  <div className={classes.image}>
                    <img alt="Presentation" src={theme.name === THEMES.LIGHT ? '/static/home/light.png' : '/static/home/dark.png'} />
                  </div>
                </Box>
                <Box mt={3}>
                  <Typography variant="body1" color="textSecondary">
                    TrustSwap is a foundational layer in decentralized finance, enabling safe, and customizable transactions for the global crypto ecosystem.
                    Check all related Trustswap stats and your own on this dashboard.
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Card className={classes.launchpadCard}>
                    <Typography variant="h1" color="secondary">
                      {launchpads}
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      Launchpads
                    </Typography>
                  </Card>
                </Box>
                <Card className={classes.card}>
                  <Box flexGrow={1}>
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                      <Box>
                        <Grid container spacing={3}>
                          <Grid item>
                            <Typography variant="h1" color="secondary">
                              {Math.round(stakingInfo.stakers / 1000) > 0
                                ? Math.floor(stakingInfo.stakers / 1000) + 'K'
                                : Math.floor(stakingInfo.stakers / 10) * 10}
                              +
                            </Typography>
                            <Typography variant="overline" color="textSecondary">
                              Stakers
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  </Box>
                </Card>
                <Box mt={3}>
                  <Button color="secondary" variant="contained" component={RouterLink} size="medium" to="/app/longtermstaking">
                    DASHBOARD
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
                <Typography variant="overline" color="secondary">
                  Introducing
                </Typography>
                <Typography variant="h1" color="textPrimary">
                  Trustswap - Dashboard
                </Typography>
                <Box mt={3} mb={1}>
                  <Typography variant="body1" color="textSecondary">
                    TrustSwap is a foundational layer in decentralized finance, enabling safe, and customizable transactions for the global crypto ecosystem.
                    Check all related Trustswap stats and your own on this dashboard.
                  </Typography>
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Card className={classes.card}>
                      <Box flexGrow={1}>
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                          <Box>
                            <Grid container spacing={3}>
                              <Grid item>
                                <Typography variant="h1" color="secondary">
                                  {launchpads}
                                </Typography>
                                <Typography variant="overline" color="textSecondary">
                                  Launchpads
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant="h1" color="secondary">
                                  {Math.round(stakingInfo.stakers / 1000) > 0
                                    ? Math.floor(stakingInfo.stakers / 1000) + 'K'
                                    : Math.floor(stakingInfo.stakers / 10) * 10}
                                  +
                                </Typography>
                                <Typography variant="overline" color="textSecondary">
                                  Stakers
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
                <Box mt={3}>
                  <Button color="secondary" variant="contained" component={RouterLink} size="medium" to="/app/longtermstaking">
                    DASHBOARD
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <Box position="relative">
                <div className={classes.shape}>
                  <img alt="Shapes" src="/static/home/shapes.svg" />
                </div>
                <div className={classes.image}>
                  <img alt="Presentation" src={theme.name === THEMES.LIGHT ? '/static/home/light.png' : '/static/home/dark.png'} />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
