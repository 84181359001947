export const SET_METAMASK = 'SET_METAMASK';
export const SET_ETH_BALANCE_BOOL = 'SET_ETH_BALANCE_BOOL';
export const SET_SWAP_BALANCE_BOOL = 'SET_SWAP_BALANCE_BOOL';
export const SET_ADDEES_NOT_STAKED = 'SET_ADDEES_NOT_STAKED';
export const SET_CONNECTED_ADDRESS = 'SET_CONNECTED_ADDRESS';
export const SET_SWAP_BALANCE = 'SET_SWAP_BALANCE';
export const SET_STAKING_VALUE = 'SET_STAKING_VALUE';
export const SET_TOTAL_STAKE_LIMIT = 'SET_TOTAL_STAKE_LIMIT';
export const SET_TOTAL_STAKE = 'SET_TOTAL_STAKE';
export const SET_EXISTING_ALLOWANCE = 'SET_EXISTING_ALLOWANCE';
export const SET_TOAPPROVE_ALLOWANCE = 'SET_TOAPPROVE_ALLOWANCE';
export const SET_ERROR = 'SET_ERROR';
export const SET_ERROR_WITH_MESSAGE = 'SET_ERROR_WITH_MESSAGE';
export const SHOW_STAKE = 'SHOW_STAKE';
export const STAKE_TX_HASH = 'STAKE_TX_HASH';
export const SET_PREAUTH_LOADING = 'SET_PREAUTH_LOADING';
export const SET_APPROVE_TX_HASH = 'SET_APPROVE_TX_HASH';
export const SET_WITHDRAW_VALUE = 'SET_WITHDRAW_VALUE';
export const SET_IS_WITHDRAW_INITIATED = 'SET_IS_WITHDRAW_INITIATED';
export const SET_STAKED_AMOUNT = 'SET_STAKED_AMOUNT';
export const SET_REWARD_VALUE = 'SET_REWARD_VALUE';
export const SET_WITHDRAW_INITIATED_DATE = 'SET_WITHDRAW_INITIATED_DATE';
export const SET_CHAIN_INFO = 'SET_CHAIN_INFO'
