import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: 'ETH - mainnet',
  chainId: 1
};

const slice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setNetwork(state, action) {
      const { name } = action.payload;
      state.name = name;
    }
  }
});

export const reducer = slice.reducer;

export const setNetwork = (chainId_) => async (dispatch) => {
  const network = {
    name: 'unknown',
    chainId: chainId_
  };

  switch (chainId_) {
    case 1:
      network.name = 'ETH - mainnet';
      network.chainId = 1;
      break;
    case 3:
      network.name = 'ETH - ropsten';
      network.chainId = 3;
      break;
    case 4:
      network.name = 'ETH - rinkeby';
      network.chainId = 4;
      break;
    case 5:
      network.name = 'ETH - goerli';
      network.chainId = 5;
      break;
    case 42:
      network.name = 'ETH - kovan';
      network.chainId = 42;
      break;
    case 97:
      network.name = 'BSC - testnet';
      network.chainId = 97;
      break;
    case 56:
      network.name = 'BSC - mainnet';
      network.chainId = 56;
      break;
    case 43113:
      network.name = 'AVAX - testnet';
      network.chainId = 43113;
      break;
    case 137:
      network.name = 'POLYGON - mainnet';
      network.chainId = 137;
      break;
    case 80001:
      network.name = 'POLYGON - mumbai';
      network.chainId = 80001;
      break;
    case 43114:
      network.name = 'AVAX - mainnet';
      network.chainId = 43114;
      break;
  }

  dispatch(slice.actions.setNetwork(network));
};
