const prerequisites = {
  metamask: false,
  swapBalance: false,
  ethBalance: false,
  addressNotStaked: false,
  connectedAddress: ''
};

const staking = {
  contractTotalStakeLimit: 0,
  contractTotalStake: 0,
  swapBalance: 0,
  stakingValue: 0,
  showStake: false,
  stakeTxHash: '',
  withdrawValue: 0
};

const allowance = {
  existing: 0,
  toApprove: 0,
  approveTxHash: ''
};

const error = {
  isError: false,
  errorMessage: 'Ooops, something happened'
};

const ui = {
  preAuth: {
    loading: false
  }
};

const initialState = {
  prerequisites,
  staking,
  allowance,
  error,
  ui
};

export default initialState;
