import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import objFromArray from 'src/utils/objFromArray';
import { toast } from 'react-toastify';

const labelsList = [
  {
    id: 'inbox',
    type: 'system_label',
    name: 'Inbox',
    unreadCount: 0,
    totalCount: 0
  }
];

const initialState = {
  mails: {
    byId: {},
    allIds: [],
    all: [],
    unread: []
  },
  labels: labelsList,
  isSidebarOpen: false,
  isComposeOpen: false
};

const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    getLabels(state, action) {
      const { labels } = action.payload;

      state.labels = labels;
    },
    getMails(state, action) {
      const mails = action.payload;

      state.mails.byId = objFromArray(mails);
      state.mails.all = mails;
      state.mails.unread = mails.filter((mail) => !mail.isRead);
      state.mails.allIds = Object.keys(state.mails.byId);
      state.labels[0].unreadCount = state.mails.unread.length;
    },
    getMail(state, action) {
      const { mail } = action.payload;

      state.mails.byId[mail._id] = mail;

      if (!state.mails.allIds.includes(mail._id)) {
        state.mails.allIds.push(mail._id);
      }
    },
    updateMailIsRead(state, action) {
      const { mailId } = action.payload;
      state.mails.unread = state.mails.all.filter((mail) => !mail.isRead && mail._id !== mailId);
      state.labels[0].unreadCount = state.mails.unread.length;
    },
    openSidebar(state) {
      state.isSidebarOpen = true;
    },
    closeSidebar(state) {
      state.isSidebarOpen = false;
    },
    openCompose(state) {
      state.isComposeOpen = true;
    },
    closeCompose(state) {
      state.isComposeOpen = false;
    }
  }
});

export const reducer = slice.reducer;

export const getLabels = () => async (dispatch) => {
  const response = await axios.get('/api/mail/labels');

  dispatch(slice.actions.getLabels(response.data));
};

export const getMails = (id) => async (dispatch) => {
  if (id) {
    const response = await axios.get(`/mail/getMails/${id}`);
    const mails = [];
    response.data.mails.forEach(function (item) {
      const { _id, messageSubject, messageBody, readBy, createdAt } = item;
      const isRead = readBy.includes(id);
      const mail = {
        _id,
        messageSubject,
        messageBody,
        createdAt,
        isRead
      };

      mails.push(mail);
    });
    dispatch(slice.actions.getMails(mails));
  }
};

export const getMail = (mailId) => async (dispatch) => {
  if (mailId) {
    const response = await axios.get(`/mail/getMail/${mailId}`);
    dispatch(slice.actions.getMail(response.data));
  }
};

export const sendMail = (messageTo, messageSubject, messageBody) => async () => {
  try {
    if (messageTo) {
      messageTo = messageTo.map((address) => address.toLowerCase());
    }

    await axios.post('/mail/addMail', {
      params: {
        messageTo,
        messageSubject,
        messageBody
      }
    });

    toast.success(`Mail with subject "${messageSubject}" successfully sent`);
  } catch (error) {
    toast.error(error);
  }
};

export const updateMailIsRead = (mailId, address) => async (dispatch) => {
  await axios.post('/mail/updateMailIsRead', {
    params: {
      mailId,
      address
    }
  });

  dispatch(slice.actions.updateMailIsRead({ mailId }));
};

export const openSidebar = () => async (dispatch) => {
  dispatch(slice.actions.openSidebar());
};

export const closeSidebar = () => async (dispatch) => {
  dispatch(slice.actions.closeSidebar());
};

export const openCompose = () => async (dispatch) => {
  dispatch(slice.actions.openCompose());
};

export const closeCompose = () => async (dispatch) => {
  dispatch(slice.actions.closeCompose());
};

export default slice;
