import React, { useEffect, useState } from 'react'
import { useReducer } from 'reinspect';
import StakingContract from './StakingContract';
import initialState from './state';
import stakeReducer from './reducer';
import { useWeb3React } from '@web3-react/core';
import { SupportedNetworks } from '../../config/constants';
import { SET_CHAIN_INFO } from './actions';

const StakingContractContext = React.createContext();
const StakingContractDispatch = React.createContext();

function StakingContractProvider({ children }) {
  const [prodState, prodDispatch] = React.useReducer(
    stakeReducer,
    initialState
  );
  const [devState, devDispatch] = useReducer(stakeReducer, initialState);
  const [stakingContract, setStakingContract] = useState(new StakingContract());
  const { chainId } = useWeb3React();

  const state = process.env.NODE_ENV === 'development' ? devState : prodState;
  const dispatch =
    process.env.NODE_ENV === 'development' ? devDispatch : prodDispatch;

  useEffect(() => {
    if (chainId) {
      if (SupportedNetworks.matic.chainId === chainId) {
        setStakingContract(new StakingContract(SupportedNetworks.matic))
      } else if (SupportedNetworks.bsc.chainId === chainId) {
        setStakingContract(new StakingContract(SupportedNetworks.bsc))
      } else if (SupportedNetworks.avalanche.chainId === chainId) {
        setStakingContract(new StakingContract(SupportedNetworks.avalanche))
      } else {
        setStakingContract(new StakingContract())
      }
    }

    dispatch({
      type: SET_CHAIN_INFO,
      payload: {
        tokenSymbol: SupportedNetworks.matic.chainId === chainId ? 'MATIC' : SupportedNetworks.bsc.chainId === chainId ? 'BNB' : SupportedNetworks.avalanche.chainId === chainId ? 'AVAX' :'ETH',
        explorerName: SupportedNetworks.matic.chainId === chainId ? 'Polygonscan' : SupportedNetworks.bsc.chainId === chainId ? 'BSCscan' : SupportedNetworks.avalanche.chainId === chainId ? 'Snowtrace' : 'Etherscan',
        networkName: SupportedNetworks.matic.chainId === chainId ? `Polygon ${process.env.REACT_APP_ETH_PROVIDER}` : SupportedNetworks.bsc.chainId === chainId ? `BSCscan ${process.env.REACT_APP_ETH_PROVIDER}` : SupportedNetworks.avalanche.chainId === chainId ? `Snwotrace ${process.env.REACT_APP_ETH_PROVIDER}` : `Ethereum ${process.env.REACT_APP_ETH_PROVIDER}`,
      },
    })
  }, [chainId])

  return (
    <StakingContractContext.Provider value={{ stakingContract, state }}>
      <StakingContractDispatch.Provider value={dispatch}>
        {children}
      </StakingContractDispatch.Provider>
    </StakingContractContext.Provider>
  );
}

function useStakingContract() {
  const context = React.useContext(StakingContractContext);
  if (context === undefined) {
    throw new Error(
      'useStakingContract must be used within a StakingContractProvider'
    );
  }
  return context;
}

function useStakingDispatch() {
  const context = React.useContext(StakingContractDispatch);
  if (context === undefined) {
    throw new Error(
      'useStakingDispatch must be used within a StakingContractProvider'
    );
  }
  return context;
}

export { StakingContractProvider, useStakingContract, useStakingDispatch };
