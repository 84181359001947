import React, { useRef } from 'react';
import { THEMES } from 'src/constants';
import { IconButton, SvgIcon, Tooltip } from '@material-ui/core';
import { Sun as SunIcon, Moon as MoonIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';

const Settings = () => {
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();

  const handleChange = (value) => {
    saveSettings({ theme: value });
  };

  const toggleTheme = () => {
    if (settings.theme == THEMES.DARK) {
      handleChange(THEMES.LIGHT);
    } else {
      handleChange(THEMES.DARK);
    }
  };

  const getIcon = () => {
    if (settings.theme == THEMES.DARK) {
      return (
        <SvgIcon fontSize="small">
          <MoonIcon />
        </SvgIcon>
      );
    } else {
      return (
        <SvgIcon fontSize="small">
          <SunIcon />
        </SvgIcon>
      );
    }
  };

  return (
    <>
      <Tooltip title="Toggle Dark/Light">
        <IconButton color="inherit" onClick={toggleTheme} ref={ref}>
          <SvgIcon fontSize="small">{getIcon()}</SvgIcon>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default Settings;
